const iconsAvailable = new Set(['degraded', 'down', 'healthy', 'offline'])
const infoStatus = new Set(['suspending', 'resuming', 'creating', 'destroying', 'deleting', 'starting'])

function getOpIcon(status, errorMessage = null) {
  if (errorMessage) {
    return 'error'
  }
  status = (status || 'unknown').toLowerCase()
  if (iconsAvailable.has(status)) {
    return `status-${status}`
  }
  if (status === 'suspended') {
    return 'button-pause'
  }
  if (infoStatus.has(status)) {
    return 'status-info'
  }
  if (status === 'running') {
    return 'status-healthy'
  }
  return 'status-down'
}

export default getOpIcon
