function getStatusColorClass(status, errorMessage = null) {
  status = (status || 'unknown').toLowerCase()
  if (errorMessage) { return '!text-yb-lava' }
  switch (status) {
    case 'healthy':
    case 'running':
      return '!text-yb-brand-primary'
    case 'degraded':
      return '!text-yb-sandbox'
    case 'suspended':
      return '!text-yb-seafoam'
    case 'resuming':
    case 'suspending':
    case 'starting':
    case 'creating':
    case 'deleting':
    case 'destroying':
      return '!text-yb-seafoam opacity-50'
    case 'down':
    case 'offline':
      return '!text-yb-lava'
    case 'unknown':
    default:
      return '!text-yb-pumpkin'
  }
}

export default getStatusColorClass

const statusScores = {
  suspended: 90,
  resuming: 90,
  suspending: 90,
  creating: 90,
  destroying: 90,
  healthy: 80,
  degraded: 75,
  requested: 60,
  unknown: 55,
  offline: 50,
  down: 25
}
export function getStatusScore(status) {
  if (status) {
    status = status.toLowerCase()
  }
  return statusScores[status] || 100
}
